import React from 'react';
import logo from './teeth.jpg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} width={450} height={358} alt="logo"/>
        <p>
          Welcome to TianzuoYu's website!
        </p>
        <a
            className="App-link"
            href="https://github.com/yutianzuo/"
            target="_blank"
            rel="noopener noreferrer"
        >
          My GitHub
        </a>
        <a
            className="App-link"
            href="../tools"
            target="_blank"
            rel="noopener noreferrer"
        >
          Some Tools
        </a>
      </header>
    </div>
  );
}

export default App;
